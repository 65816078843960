// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {addDoc, collection, getDocs, deleteDoc, updateDoc, doc, serverTimestamp} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAPk_dqTVJRrdLHp-Us9cCsxxrCIA2Z-SU",
  authDomain: "keino-portfolio-site.firebaseapp.com",
  projectId: "keino-portfolio-site",
  storageBucket: "keino-portfolio-site.appspot.com",
  messagingSenderId: "950015299247",
  appId: "1:950015299247:web:e6609f90f47ce86bde0e5a"
};

///Questions
// Function to add a question
async function addQuestion(question) {
  try {
    const docRef = await addDoc(collection(db, "SHI-questions"), {
      num: question.num,
      question: question.question,
      timestamp: serverTimestamp(),
      answer: ""
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

// Function to delete a question
async function deleteQuestion(questionId) {
  try {
    await deleteDoc(doc(db, "SHI-questions", questionId));
    console.log("Question deleted successfully");
  } catch (error) {
    console.error("Error deleting question: ", error);
  }
}

// Function to update a question
async function updateQuestion(questionId, updatedQuestion) {
  try {
    await updateDoc(doc(db, "SHI-questions", questionId), updatedQuestion);
    console.log("Question updated successfully");
  } catch (error) {
    console.error("Error updating question: ", error);
  }
}






/// Favorite color = field 
///post Comments 
async function addComment(comment) {
    try {
      const docRef = await addDoc(collection(db, "comments"), {
        name: comment.name,
        title: comment.title,
        message: comment.message,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

// Post Messages
async function addMessage(message) {
    try {
      const docRef = await addDoc(collection(db, "messages"), {
        name: message.name,
        email: message.email,
        message: message.message,
        timestamp: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/// exports for component use
export default db;
export {addComment, addMessage, addQuestion, deleteQuestion, updateQuestion}