import React, { useContext } from 'react';
import "./SHI.css"
import { DataContext } from '../Redux/DataContext';

export default function SHIQRender() {
    const { allQuestions } = useContext(DataContext);

    const renderQA = allQuestions.map(quest => (
        <div className="QA-div" key={quest.id}>
            <div className="big-num">{quest.num}</div>
            <div className="text-div">
            <div className="question-div">{quest.question}</div>
            <div className="answer-div">{quest.answer}</div>

            </div>
        </div>
    ));

    return <div className="QA-container">{renderQA}</div>;
}
