import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SHI from './components/SHI';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {DataProvider} from '../src/Redux/DataContext'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <DataProvider>
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/SHI-assessment" element={<SHI />} />
      </Routes>
    </Router>
  </React.StrictMode>
  </DataProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

////////////////////
function animate () {
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    console.log(entry)
    // if(entry.isIntersecting) {
    //   entry.target.classList.add("show");
    // } else {
    //   entry.target.classList.remove ("show");
    // }
    entry.target.classList.toggle('show', entry.isIntersecting);
  })
})

const hiddenElements = document.querySelectorAll(".hidden");
hiddenElements.forEach((element) => observer.observe(element));
}

animate()