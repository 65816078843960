import "./Intro.css"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";



function Intro() {

    const [ref, inView] = useInView()
    const controls = useAnimation()

        useEffect(() => {
            if (inView) {
                controls.start({
                    y: 0,
                    transition: {
                        duration: 1.2,
                        type: "spring",
                        bounce: 0.3,
                    }
                });
            }
            if (!inView) {
                controls.start({
                    y: "-100vh",
                    transition: {
                        duration: 1.2,
                        type: "spring",
                        bounce: 0.3,
                    }
                });
            }
            console.log(inView);
        }, [inView]);


    document.addEventListener("DOMContentLoaded", () => {
        const element = document.querySelector(".pages");
        element.scrollIntoView({ behavior: "smooth" });
      });

    return (
        <section ref={ref} className="pages intro-page" id="intro-container">
        

            <motion.div id="intro-page"
                  animate={ controls} 
            >
            
                <p className="words"> Hi, I'm</p>
                <p id="Keino"> Keino</p>


                <div className="wrapper">
                    
                    <span className = "words long">Software Developer and </span>
                    
                    <span className="slidingVertical">
                        <span className="splash">tinkerer</span>
                        <span className="splash">builder</span>
                        <span className="splash">engineer</span>
                        <span className="splash">creator</span>
                        <span className="splash">alchemist</span>
                    </span>

                </div>

            
            </motion.div>

        
        </section>
    );
}

export default Intro;
