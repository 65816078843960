import React, { useEffect, useState, createContext } from "react";
import db from "./firebase-config";
import {
    addComment,
    addMessage,
    addQuestion,
    deleteQuestion,
    updateQuestion,
} from "./firebase-config";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
    addDoc,
    collection,
    getDocs,
    serverTimestamp,
} from "firebase/firestore";

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [comment, setComment] = useState({});
    const [question, setQuestion] = useState({});
    const [allQuestions, setAllQuestions] = useState([]);
    const [allComments, setAllComments] = useState([]);
    const [message, setMessage] = useState({});
    const [allMessages, setAllMessages] = useState([]);

    //// get all comments
    async function getAllComments() {
        const querySnapshot = await getDocs(collection(db, "comments"));
        const comments = [];
        querySnapshot.forEach((doc) => {
            comments.push(doc.data()); // push is important or it will returning the last one.
        });
        setAllComments(comments);
    }

    useEffect(() => {
        getAllComments();
    }, []);

    /// get all questions

    const [sortAsc, setSortAsc] = useState(true);

    async function getAllQuestions() {
      try {
        const querySnapshot = await getDocs(collection(db, "SHI-questions"));
        const quest = [];
        querySnapshot.forEach((doc) => {
          quest.push({ id: doc.id, ...doc.data() });
        });
    
        // Sort the questions based on the `num` property
        const sortedQuestions = sortAsc
          ? quest.sort((a, b) => a.num - b.num) // Ascending order
          : quest.sort((a, b) => b.num - a.num); // Descending order
    
        setAllQuestions(sortedQuestions);
      } catch (error) {
        console.error("Error getting questions: ", error);
        return [];
      }
    }


    useEffect(() => {
      getAllQuestions();
    }, [sortAsc]);


    // getTimestamp function
    function getTimeStampString(timestamp) {
        const secondsAgo = Math.floor(
            (Date.now() - timestamp.toMillis()) / 1000
        );
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);

        if (daysAgo >= 30) {
            return `${Math.floor(daysAgo / 30)} months ago`;
        } else if (daysAgo >= 7) {
            return `${Math.floor(daysAgo / 7)} weeks ago`;
        } else if (daysAgo > 0) {
            return `${daysAgo} days ago`;
        } else if (hoursAgo > 0) {
            return `${hoursAgo} hours ago`;
        } else if (minutesAgo > 0) {
            return `${minutesAgo} minutes ago`;
        } else {
            return `just now`;
        }
    }




    return (
        <DataContext.Provider
            value={{
                addComment,
                comment,
                allComments,
                setComment,
                setAllComments,
                addMessage,
                message,
                allMessages,
                setMessage,
                setAllMessages,
                getTimeStampString,
                addQuestion,
                deleteQuestion,
                updateQuestion,
                question, setQuestion,
                setAllQuestions,
                allQuestions,
                getAllComments,
                sortAsc,
                setSortAsc,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };
