import React, { useState, useEffect } from "react";
import "./About.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import VisibilitySensor from "react-visibility-sensor";


function About() {


const section1 = `Hello there! My name is Keino, and I am a
software engineer with a passion for
creating amazing digital experiences. I've
been fascinated with engineering since I was
young, tinkering with toys - especially
electronic ones - and transforming them into
a state where they were greater than the sum
of their parts. I’m a bit of a Greek
Mythology buff, and the one figure I most
identify with is Hephaestus. If you’re not
familiar, he’s the dude who forged Zeus’s
thunderbolt, Poseidon’s trident, Aphrodite’s
bow, and Helios’s chariot, to name a few. He
certainly was a primordial developer.`

const section2 = `I am no stranger to the forge. I have worked
professionally as a financial analyst,
primarily for healthcare organizations,
since 2015. I enjoyed most facets of my
career, but the part that really lit a fire
in my belly is building financial models to
satiate the organization's data-driven
decision-making needs.`

const section3 = `One of my primary goals is to create
accessible, inclusive digital products and
experiences. To achieve this, I enrolled in
a full-time immersive Full-stack Software
Engineering bootcamp at the challenging but
very rewarding Flatiron School. The tech
stack I learned includes Python, Flask &
Sqlalchemy for the backend with Javascript &
React for the frontend. I have also
independently expanded my knowledge base to
more modular UI styling with Tailwind &
Bootstrap, as well as data structures and
algorithms to help round out my skills.`

const section4 = `When I am not coding, you can find me
geeking out over real estate, resource
management RPG games, or woodworking.
Recently, I have been excited to launch my
portfolio site to showcase my recent
projects.`

const section5 = `Thank you for taking the time to read my
brief introduction. I am always down for a
chat, whether you have something to teach,
something you would like to learn, or just
want to say hello. My virtual door is always
open.`





    return (
        <section className="about-container" id="about-container1" >
          <div className="about-bg-image"></div>
          
          <div className="about-text-img-container">

          <div className="about-text-container">
            <div className="about-text">
              <p>{section1}</p>
              <br></br>
              <p>{section2}</p>
              <br></br>
              <p>{section3}</p>
              <br></br>
              <p>{section4}</p>
              <br></br>
              <p>{section5}</p>
            </div>
          </div>


          </div>

        </section>
    );
}

export default About;
