import React, { useEffect, useState } from "react";
import {
    FaReact,
    FaJsSquare,
    FaCss3Alt,
    FaHtml5,
    FaGithubSquare,
    FaVercelSquare,
} from "react-icons//fa";
import { GrDeploy, GrHeroku } from "react-icons/gr";
import { SiVercel } from "react-icons/si";

import "./stylePortfolio.css";
// import "../public/xero-code-gif.gif"
import "./Portfolio.css";

function Portfolio() {
    const [allProjects, setAllProjects] = useState([]);

    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = (id) => {
        if (isFlipped === id) {
            setIsFlipped(false);
            return;
        }
        setIsFlipped(id);
    };

    const icons = {
        react: FaReact,
        js: FaJsSquare,
        html: FaHtml5,
        css: FaCss3Alt,
        deployed: GrDeploy,
        heroku: GrHeroku,
        github: FaGithubSquare,
        vercel: SiVercel,
    };

    ///portfolio data
    useEffect(() => {
        setAllProjects([
            {
                id: 1,
                name: "Doggelganger",
                description: "Portfolio Bot customizable to you specifications",
                tech: [icons.react, icons.js, icons.css, icons.heroku],
                thumb: "https://i.imgur.com/VlsxqnE.png",
                github: "https://github.com/KeinoC/Doppleganger",
                demo: "https://doppelganger.keino.dev/",
            },
            {
                id: 2,
                name: "Garden 249",
                description: "A website for a local event space business",
                tech: [icons.react, icons.js, icons.css, icons.heroku],
                thumb: "https://imgur.com/eKkg3wT.png",
                github: "https://github.com/KeinoC/G249-App",
                demo: "https://garden249.keino.dev/",
            },
            {
                id: 3,
                name: "Tic Tac Toe",
                description: "First Attempt at creating custom tic-tac-toe logic",
                tech: [icons.react, icons.js, icons.css, icons.vercel],
                thumb: "https://imgur.com/aZhqtaQ.png",
                github: "https://github.com/KeinoC/tic-tac-toe-kc",
                demo: "https://tic.keino.dev/",
            },
        ]);
    }, []);


    ///animate when icons scroll into view

    const Portfolio = allProjects.map((project, index) => {
        return (
            <div className="portfolio-card " key={index}>
                <div
                    className="portfolio-flip-container"
                    onClick={() => handleFlip(project.id)}
                >
                    <div
                        className={`portfolio-flip-card ${
                            isFlipped === project.id ? "flipped" : ""
                        }`}
                    >
                        <div className="portfolio-image-div">
                            <img
                                className="portfolio-img"
                                src={project.thumb}
                            />
                        </div>

                        <div className={"portfolio-info-div"}>
                            <p>{project.name}</p>
                            <p>{project.description}</p>
                            <a href={project.demo}>Demo</a>
                <div className="portfolio-tech-div">
                    {project.tech.map((icon, index) => {
                        const Icon = icon;
                        return (
                            <Icon
                                className="portfolio-tech-icon "
                                key={icon}
                                data-animate
                            />
                        );
                    })}
                </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    });

    return (
        <section className="portfolio-container" id="portfolio-container">
            {Portfolio}
        </section>
    );
}

export default Portfolio;
