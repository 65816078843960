import React from "react";
import "./Navbar.css"; // Import CSS file

function Navbar () {
    return (
        <div id="navbar-container">
            <a href="#intro-container" className="nav-item">INTRO</a>
            <a href="#about-container1" className="nav-item">ABOUT ME</a>
            <a href="#portfolio-container" className="nav-item">PORTFOLIO</a>
            <a href="#contact-container1" className="nav-item">SAY HI</a>
        </div>
    )
}

export default Navbar;