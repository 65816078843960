import React, { useEffect, useState, useContext } from "react";
import { DataContext } from "../Redux/DataContext";
import "./Contact.css";
import { Button, Icon, Modal, Input } from "semantic-ui-react";

import {
    Divider,
    Image,
    Segment,
    Reveal,
    Card,
    Dropdown,
} from "semantic-ui-react";

function Contact() {
    const {
        addComment,
        comment,
        setComment,
        getAllComments,
        allComments,
        setAllComments,
        addMessage,
        message,
        setMessage,
        getAllMessages,
        allMessages,
        setAllMessages,
        getTimeStampString,
    } = useContext(DataContext);

    console.log(allComments);

    const [guestName, setGuestName] = useState("");
    const [commentsList, setCommentsList] = useState("");

    const avatarOptions = [
        {
            key: 1,
            text: "|",
            value: "|",
            image: {
                avatar: true,
                src: "https://cdn.icon-icons.com/icons2/2630/PNG/512/avatar_woman_people_girl_glasses_icon_159125.png",
            },
            sex: "M",
        },
        {
            key: 2,
            text: "||",
            value: "||",
            image: {
                avatar: true,
                src: "https://cdn.icon-icons.com/icons2/2630/PNG/512/avatar_man_boy_people_black_race_african_icon_159091.png",
            },
            sex: "F",
        },
    ];

    const comments = [
        {
            name: "John Doe",
            image: "https://cdn.icon-icons.com/icons2/2630/PNG/512/avatar_woman_people_girl_glasses_icon_159125.png",
            title: "Software Engineer",
            message: "Well Doe'n! See what i did there?!",
        },
        {
            name: "Jane Doe",
            image: "https://cdn.icon-icons.com/icons2/2630/PNG/512/avatar_man_boy_people_black_race_african_icon_159091.png",
            title: "Engineering Manager",
            message: "So cool!",
        },
        {
            name: "John Doe",
            image: "https://cdn.icon-icons.com/icons2/2630/PNG/512/avatar_woman_people_girl_glasses_icon_159125.png",
            title: "Software Engineer",
            message: "Well Doe'n! See what i did there?!",
        },
    ];

    //// Comments process
    const handleNameChangeC = (e) => {
        setComment({ ...comment, name: e.target.value });
    };

    const handleTitleChange = (e) => {
        setComment({ ...comment, title: e.target.value });
    };

    const handleCommentChange = (e) => {
        setComment({ ...comment, message: e.target.value });
    };

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        addComment(comment);
        setAllComments([...allComments, comment]);
        setComment({});
    };

    /////Comment Display List Process
    useEffect(() => {
        if (!Array.isArray(allComments)) {
            return;
        }

        const sortedComments = [...allComments].sort((a, b) => {
            const timeA = a.timestamp?.seconds ?? 0;
            const timeB = b.timestamp?.seconds ?? 0;
            return timeB - timeA;
          });


console.log(sortedComments)
        const list = sortedComments.map((comment, index) => {
            return (
                <Card className="comment-card" key={index} >
                    <Card.Content>
                        <Card.Header >{comment.name}</Card.Header>
                        <Card.Meta>{comment.title}</Card.Meta>
                        <Card.Description>{comment.message}</Card.Description>
                        {comment.timestamp ? <Card.Meta>{getTimeStampString(comment.timestamp)}</Card.Meta> : <></>}
                    </Card.Content>
                </Card>
            );
        });



        setCommentsList(list);
    }, [allComments]);

    //// Visitor message process
const handleNameChangeM = (e) => {
    setMessage({...message, name: e.target.value })
}

    const handleEmailChange = (e) => {
        setMessage({...message, email: e.target.value });
    }

    
    const handleMessageChange = (e) => {
        setMessage({...message, message: e.target.value });
    }

    const handleMessageSubmit = (e) => {
        e.preventDefault();
        addMessage(message);
        setAllMessages([...allMessages, message]);
        setMessage({});
    
    }

    return (
        <section className="contact-container" id="contact-container1">
            <div className="button-div">
                <Reveal animated="move up">
                    <Reveal.Content className="shout-image-container" visible>
                        <img
                            className="contact-button"
                            // src="https://media.tenor.com/OtjZkfNZTUIAAAAC/loud-yell.gif"
                            // src="https://media.tenor.com/j1wmFty84OQAAAAi/cheer-up-honour.gif"
                            src="https://media.tenor.com/tlTqu_jOm5QAAAAC/banksy-wall.gif"
                        />
                        <li className="overlay-text">Sign Guest Book</li>
                    </Reveal.Content>

                    <Reveal.Content hidden>
                        <div className="reveal-container">
                            <form
                                className="shout-form"
                                onSubmit={handleCommentSubmit}
                            >
                                <Input
                                    type="text"
                                    placeholder="Enter your name"
                                    value={comment.name || ""}
                                    onChange={handleNameChangeC}
                                />

                                <br />

                                <Input
                                    type="text"
                                    placeholder="Note: Title / Company / Relationship"
                                    value={comment.title || ""}
                                    onChange={handleTitleChange}
                                />
                                <br />

                                <Input
                                    icon
                                    iconPosition="left"
                                    placeholder="Comment"
                                >
                                    <input
                                        value={comment.message || ""}
                                        className="message"
                                        onChange={handleCommentChange}
                                    />
                                </Input>

                                <br />
                                <Button
                                    circular
                                    icon="send"
                                    className="circle-button"
                                    color="olive"
                                ></Button>
                                <br />
                            </form>
                            {/* <div className="avatar-option-container">
                            <Dropdown
                                inline
                                scrolling
                                options={avatarOptions}
                                defaultValue={avatarOptions[0].value}
                            />
                        </div> */}
                        </div>
                    </Reveal.Content>
                </Reveal>

                <Divider className="divider" horizontal inverted fitted>
                    {" "}
                    OR{" "}
                </Divider>

                <Reveal animated="move down">
                    <Reveal.Content className="whisper-image-container" visible>
                        <img
                            className="contact-button"
                            alt="image"
                            // src="https://media.tenor.com/s8EmEapor9kAAAAC/dexter-lab.gif"
                            // src="https://media.tenor.com/8XkrkPCXjGwAAAAC/spy-incognito.gif"
                            src="https://media.tenor.com/WtshpF3GcEYAAAAC/bergmite-pokemon.gif"
                        />
                        <li className="overlay-text">Send Direct Message</li>
                    </Reveal.Content>

                    <Reveal.Content hidden>
                        <div className="reveal-container">
                            <form 
                            className="message-form"
                            onSubmit={handleMessageSubmit}
                            >

                                <Input
                                    icon
                                    iconPosition="left"
                                    placeholder="Name"
                                    value={message.name || ""}
                                    onChange={handleNameChangeM}
                                >
                                    <input />
                                    <Icon name="user" />
                                </Input>
                                <br />

                                <Input
                                    icon
                                    iconPosition="left"
                                    placeholder="Email"
                                    value={message.email || ""}
                                    onChange={handleEmailChange}
                                >
                                    <input onChange={handleEmailChange} />
                                    <Icon name="mail" />
                                </Input>

                                <br />

                                <Input
                                    icon
                                    iconPosition="left"
                                    placeholder="Private Message"
                                >
                                    <input className="message" 
                                    value = {message.message || ""}
                                    onChange={handleMessageChange} />
                                </Input>
                                <br />
                                <Button
                                    circular
                                    icon="send"
                                    className="circle-button"
                                    color="olive"
                                ></Button>
                                <br />
                            </form>
                        </div>
                    </Reveal.Content>
                </Reveal>
            </div>

            <div className="comment-container">
                {/* <h2 className="comment-header">Comments & Feedback</h2> */}
                <Card.Group itemsPerRow={1}>{commentsList}</Card.Group>
            </div>
        </section>
    );
}

export default Contact;
