import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../Redux/DataContext";
import "./SHI.css";
import SHIQForm from "./SHIQForm";
import SHIQRender from "./SHIQRender";
import { Button, Icon, Modal, Input, Image, Reveal } from "semantic-ui-react";

export default function SHI() {
    const {
        question,
        setQuestion,
        addQuestion,
        deleteQuestion,
        updateQuestion,
        sortAsc,
        setSortAsc,
    } = useContext(DataContext);


    return <div className="shi-page">



<Reveal animated='move down'>
    <Reveal.Content visible>
      <div className="add-question-visible-div"> Add Question </div>
    </Reveal.Content>
    <Reveal.Content hidden>
     <SHIQForm />
    </Reveal.Content>
  </Reveal>

<nav className="navbar">
    <button className="sort-button button" onClick={()=>setSortAsc(!sortAsc)}>{sortAsc ? "Toggle Sort (ASC)" : "Toggle Sort (DEC)"}</button>
</nav>

  <SHIQRender />

        </div>;
}
