

import React, { useState, useContext } from 'react';
import { Link, Route, Router } from 'react-router-dom'
import './App.css';
import Navbar from './components/Navbar';
import Doppelganger from './components/Doppelganger'
import About from './components/About';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Intro from './components/Intro';
import Sidebar from "./components/Sidebar"
import { Element } from 'react-scroll';
import 'semantic-ui-css/semantic.min.css'
import { Divider, Image, Segment } from 'semantic-ui-react'
import {DataProvider} from '../src/Redux/DataContext'
import { motion } from 'framer-motion'

function App() {
  const [activeSection, setActiveSection] = useState(null);

  function handleSetActive(sectionName) {
    setActiveSection(sectionName);
  }



  return (
    <DataProvider>
    <div className="App">
      <Navbar />
      <div id="page">
        {/* <Sidebar activeSection={activeSection} setActiveSection={handleSetActive} /> */}
        {/* <Nav activeSection={activeSection} /> */}
        <div id="content-body-container">
      <Doppelganger />

          <Element name="Intro">
            <Intro />
          </Element>


          <Divider className = "divider" horizontal inverted fitted> About Me </Divider> 
          <Element name="About Me">
            <About />
          </Element>

          <Divider className = "divider" horizontal inverted fitted> My Work </Divider> 

          <Element name="My Work">
            <Portfolio />
          </Element>

          <div className="spacer2"></div>
          <Divider className = "divider" horizontal inverted fitted> Say Hi </Divider> 
          <div className="spacer1"></div>

          <Element name="Say Hi">
            <Contact />
          </Element>
        </div>
      </div>
    </div>
    </DataProvider>
  );
}

export default App;
