import React, { useState, useEffect } from "react";
import "./Sidebar.css";

function Sidebar() {
    const [activeSection, setActiveSection] = useState("Intro");

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            let newActiveSection = activeSection;

            sections.forEach((section, index) => {
                const sectionTop = section.offsetTop;
                const sectionBottom = sectionTop + section.offsetHeight;

                if (
                    scrollPosition >= sectionTop &&
                    scrollPosition < sectionBottom
                ) {
                    newActiveSection = section.id;
                }
            });

            setActiveSection(newActiveSection);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [activeSection]);

    const handleScrollToSection = (sectionId) => {
        const section = document.querySelector(`#${sectionId}`);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
            setActiveSection(sectionId);
        }
    };

    const handleScrollToNextSection = () => {
        const sections = document.querySelectorAll("section");
        const currentSectionIndex = Array.from(sections).findIndex(
            (section) => section.id === activeSection
        );
        if (currentSectionIndex < sections.length - 1) {
            const nextSection = sections[currentSectionIndex + 1];
            nextSection.scrollIntoView({ behavior: "smooth", block: "start" });
            setActiveSection(nextSection.id);
        }
    };

    const handleScrollToPrevSection = () => {
        const sections = document.querySelectorAll("section");
        const currentSectionIndex = Array.from(sections).findIndex(
            (section) => section.id === activeSection
        );
        if (currentSectionIndex > 0) {
            const prevSection = sections[currentSectionIndex - 1];
            prevSection.scrollIntoView({ behavior: "smooth", block: "start" });
            setActiveSection(prevSection.id);
        }
    };

    return (
        <aside className="sidebar">
            <button onClick={handleScrollToPrevSection}>Prev</button>
            <nav className="sidebar-nav">
                <ul>
                    <li
                        className={activeSection === "Intro" ? "active" : ""}
                        onClick={() => handleScrollToSection("Intro")}
                    >
                        <a>Intro</a>
                    </li>
                    <li
                        className={activeSection === "About Me" ? "active" : ""}
                        onClick={() => handleScrollToSection("About Me")}
                    >
                        <a>About Me</a>
                    </li>
                    <li
                        className={activeSection === "My Work" ? "active" : ""}
                        onClick={() => handleScrollToSection("My Work")}
                    >
                        <a>My Work</a>
                    </li>
                    <li
                        className={activeSection === "Say Hi" ? "active" : ""}
                        onClick={() => handleScrollToSection("Say Hi")}
                    >
                        <a>Say Hi</a>
                    </li>
                </ul>
                <div className="sidebar-line"></div>
            </nav>
            <button onClick={handleScrollToNextSection}>Next</button>
        </aside>
    );
}

export default Sidebar;
