import React, { useContext } from 'react';
import "./SHI.css"
import { DataContext } from '../Redux/DataContext';

export default function SHIQForm() {
    const { setQuestion, question, addQuestion } = useContext(DataContext);

    function handleChange(e) {
        const { value, name } = e.target;
        setQuestion(prevQuestion => ({
            ...prevQuestion,
            [name]: value
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        addQuestion(question);
        setQuestion({});
    }

    return (
        <form className="question-form-container">
            <input
                type="text"
                className="num-input"
                placeholder="#"
                name="num"
                value={question.num || ''}
                onChange={handleChange}
            />
            <input
                type="text"
                className="question-input"
                placeholder="Question.."
                name="question"
                value={question.question || ''}
                onChange={handleChange}
            />
            <button className="submit" onClick={handleSubmit}>Add Question</button>
        </form>
    );
}
